import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  StyledFooter,
  StyledFooterLinkContainer,
  StyledFooterLinks,
  StyledLogo,
  StyledLogoContainer,
  StyledFooterInfo,
  BrandLogoContainer,
  FooterRow,
} from './styles.js';

import CherryLogo from '../../images/cherryLogo.svg';
import Linkedin from '../../images/linkedin-logo.svg';
import Twitter from '../../images/twitter-logo.svg';
import { useScroll } from '../Scroller/index.js';
import { navigate } from '@reach/router';
import FooterLinks from './FooterLinks.js';

function Footer({
  closeModal,
  openCookieSettings,
  openPrivacyPolicy,
  displayMenu,
}) {
  const links = [
    ['About us', 1, 'about'],
    ['Culture', openCulturePage, 'culture'],
    ['Showreel', 2, 'our-work'],
    ['Services', 3, 'services'],
    ['Contact', 5, 'contact'],
    ['Cookie settings', openCookieSettings, 'cookies'],
    ['Privacy & cookies policy', openPrivacyPolicy, 'privacy'],
  ];

  function openCulturePage() {
    if (typeof window !== 'undefined' && window.location.pathname === '/') {
      navigate('/culture/#');
    } else {
      scrollTo(0);
    }
  }

  const { scrollTo } = useScroll();

  useEffect(() => {
    switch (window.location.hash.split('#')[1]) {
      case 'cookies':
        openCookieSettings();
        break;
      case 'privacy':
        openPrivacyPolicy();
        break;
      case 'culture':
        openCulturePage();
        break;
      default:
    }
  }, []);

  // ToDo - Split logos into more components e.g Social media logos, company logos etc

  return (
    <StyledFooter>
      <FooterRow reverse>
        <StyledFooterLinkContainer alignItemsLap="center" marginLeftDesk="7%">
          {displayMenu !== false && (
            <FooterLinks links={links} scrollTo={scrollTo} />
          )}
        </StyledFooterLinkContainer>
        <StyledLogoContainer>
          <BrandLogoContainer
            onClick={() => {
              closeModal ? closeModal() : scrollTo(0);
            }}
          >
            <CherryLogo />
          </BrandLogoContainer>
        </StyledLogoContainer>
      </FooterRow>
      <FooterRow justified>
        <StyledLogoContainer>
          <StyledFooterInfo>
            &#9400; 2022 Cherry Advertising Limited, an AVENIR GLOBAL company.
            All rights reserved.
          </StyledFooterInfo>
        </StyledLogoContainer>

        <StyledLogoContainer>
          <StyledLogo
            href="https://www.linkedin.com/company/cherry-advertising"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin />
          </StyledLogo>
          {/* <StyledLogo
            href="https://twitter.com/howverycherry"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter />
          </StyledLogo> */}
        </StyledLogoContainer>
      </FooterRow>
    </StyledFooter>
  );
}

Footer.propTypes = {
  showPopulatedModal: PropTypes.func,
  displayMenu: PropTypes.bool,
  closeModal: PropTypes.func,
  setLocation: PropTypes.func,
  setModal: PropTypes.func,
};

export default Footer;
